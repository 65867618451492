import React from 'react';
import PropTypes from 'prop-types';
import StaticPage from '../templates/static-page/static-page';

const NotFoundPage = ({ data }) => (
  <StaticPage data={data} />
);

NotFoundPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "static-page" }, pageTitle: { eq: "404" } }) {
      fields{
        slug
      }
      frontmatter {
        ...SeoFragment
        date(formatString: "MM/YYYY")
        title
        subtitle
        description
        tags
        author
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1800, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        filedownload {
          id
          name
          publicURL
        }
        quoted
        blocks {
          title
          description
          filedownload {
            id
            name
            publicURL
          }
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          quoted
        }
        formId
      }
    }
  }
`;

 